hr {
    margin:.25rem;
}

.MainRecipeListContainer {
    display: flex;
    flex-direction: column;
    align-content: center
}

.MainRecipeListContainer .DividerDiv {
    display: flex;
    flex-direction: column;
    align-content: center;
    background-color: #e6ffff;
}

.MainRecipeListContainer .DividerDiv h3 {
    margin: auto;
}

/*.MainRecipeListContainer .h3 {*/
/*   margin: auto;*/
/*    background-color: #e6ffff;*/
/*    padding-top: 10px;*/
/*    padding-bottom: 10px;*/
/*    padding-left: 50px;*/
/*    padding-right: 50px;*/
/*    margin-top: 10px;*/
/*}*/

/*.MainRecipeListContainer .ListRow .GridCard {*/
/*    width: 310px;*/
/*    height: 480px;*/
/*}*/


.MainRecipeListContainer .TopRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    margin-bottom: 15px;
    margin-top: 15px
}

.MainRecipeListContainer .ListRow{
    justify-content: space-around
}

.MainRecipeListContainer .ListRow .GridCard {
    width: 250px;
    height: 370px;
}


/*.MainRecipeListContainer .ListRow .GridCard img {*/
/*    width: 300px;*/
/*    height: 300px;*/
/*}*/

.MainRecipeListContainer .ListRow .GridCard img {
    width: 240px;
    height: 250px;
}

/*.MainRecipeListContainer .ListRow .GridCard .TitleDiv {*/
/*    height: 100px;*/
/*}*/

.MainRecipeListContainer .ListRow .GridCard .TitleDiv {
    height: 50px;
}

.MainRecipeListContainer .ListRow .GridCard .LikesDiv {
    display: flex;
    flex-direction: row;
}

.MainRecipeListContainer .ListRow .GridCard p{
    margin-right: 5px;
    font-size: 14px;
    margin-left: 1px;
}

.MainRecipeListContainer .ListRow .GridCard .LikesDiv p{
    font-size: 14px;
    margin-right: 5px;
}


.MainRecipeListContainer button {
    width: 300px;
    margin: 7px 5px;
}