.GridCard {
    height: fit-content;
    width: auto;
}

.MainDiv {
    display: flex;
    flex-direction: column;
    align-content: center
}

.CommentDiv {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

/*.CommentDiv {*/
/*    margin: auto;*/
/*    width: 92%;*/
/*    padding: 10px;*/
/*    text-align: center;*/
/*}*/

.NoteAddDiv {
    padding: 10px;
    border: 1px solid black;
    align-content: center;
    justify-content: center;
}

.NoteAddDiv label {
   width: 75%;
}

.NoteAddDiv button {
    width: 30%;
    margin-right: 15px;
}

Button {
    width: 85%;
    margin: auto;
    /*margin: 10px;*/
}

select {
    margin-left: 15px;
    margin-right: 15px;
}

label {
    margin-left: 15px;
    margin-bottom: 10px;
}

p {
    margin-left: 15px;
}

textarea {
    width: 85%;
    margin: auto;
}
