.HeaderDiv {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    background-color: #e6ffff;
    margin-bottom: 15px;
}

