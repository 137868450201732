.Container {
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.GridCard {
    padding: 5px;
    margin: 10px;
    width: 300px;
    height: 350px;
}

.GridCard img {
    height: 80%;
    width: 100%;
    /*width: 300px;*/
    /*height: 300px;*/
}

.AddRecipe {
    padding: 5px;
    margin: 20px;
}