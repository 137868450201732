.AddRecipe .form .multi-select-row button {
    width: 120px;
    margin: 5px;
}

.AddRecipe button {
    margin-top: 10px;
    width: 250px;
}

.AddRecipe .form input {
    margin-left: 8px;
}

.AddRecipe .form label {
    margin-top: 8px;
}