.DetailContainer {
    display: flex;
    flex-direction: row;
}

.DetailContainer .DetailRecipe {
    flex: 3 1 400px;

}

.DetailContainer .DetailExtras {

    flex: 1 1 200px;
}

.TabContainer {
    width: 100%
}

.TabRecipe {
    width: 100%
}

.TabDetail {
    width: 100%
}